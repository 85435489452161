import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';

import { intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';

import { FieldSelect } from '../../components';

import css from './AuthenticationPage.module.css';

/**
 * Return React Final Form Field that allows selecting store neighborhood.
 *
 * @param {*} props containing name, storeNeighborhood, intl
 * @returns React Final Form Field component to select store neighborhood
 */
const FieldSelectStoreNeighborhood = props => {
  const { rootClassName, className, name, storeNeighborhood, intl } = props;
  const classes = classNames(rootClassName || css.userTypeSelect, className);

  return (
    <>
      <FieldSelect
        required
        id={name}
        name={name}
        className={classes}
        label={intl.formatMessage({ id: 'FieldSelectStoreNeighborhood.label' })}
        validate={validators.required(intl.formatMessage({ id: 'FieldSelectStoreNeighborhood.required' }))}
      >
        <option disabled selected value="">
          {intl.formatMessage({ id: 'FieldSelectStoreNeighborhood.placeholder' })}
        </option>
        {storeNeighborhood.map(store => {
          return (
            <option key={store.option} value={store.option}>
              {store.label}
            </option>
          );
        })}
      </FieldSelect>
    </>
  )
};

FieldSelectStoreNeighborhood.defaultProps = {
    rootClassName: null,
    className: null,
    hasExistingUserType: false,
};

FieldSelectStoreNeighborhood.propTypes = {
    rootClassName: string,
    className: string,
    name: string.isRequired,
    storeNeighborhood: propTypes.storeNeighborhood.isRequired,
    hasExistingUserType: bool,
    intl: intlShape.isRequired,
};

export default FieldSelectStoreNeighborhood;
