import React, { useState } from 'react';
import { bool, node, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import { Map } from '../../../components';
import { types as sdkTypes } from '../../../util/sdkLoader';
const { LatLng } = sdkTypes;

import {
  FormattedMessage,
  injectIntl,
  intlShape,
} from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import * as validators from '../../../util/validators';
import { getPropsForCustomUserFieldInputs } from '../../../util/userHelpers';
import { isStoreOwner } from '../../../util/userHelpers';

import {
  Form,
  PrimaryButton,
  FieldTextInput,
} from '../../../components';

import FieldSelectUserType from '../FieldSelectUserType';
import UserFieldDisplayName from '../UserFieldDisplayName';
import UserFieldPhoneNumber from '../UserFieldPhoneNumber';

import css from './SignupForm.module.css';
import UserField from '../UserField';
import FieldSelectStoreNeighborhood from '../fieldSelectStoreNeighborhood';

const getSoleUserTypeMaybe = userTypes =>
  Array.isArray(userTypes) && userTypes.length === 1
    ? userTypes[0].userType
    : null;

const SignupFormComponent = props => {
  const { setAuthPageLocation } = props;
  const [localLocation, setLocalLocation] = useState({
    lat: null,
    lng: null,
    address: null,
  });

  const handleLocationSelect = newLocation => {
    setAuthPageLocation(newLocation);
    setLocalLocation(newLocation);
  };

  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      initialValues={{
        userType:
          props.preselectedUserType || getSoleUserTypeMaybe(props.userTypes),
      }}
      render={formRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          handleSubmit,
          inProgress,
          invalid,
          intl,
          termsAndConditions,
          preselectedUserType,
          userTypes,
          userFields,
          values,
          form
        } = formRenderProps;

        const { userType } = values || {};
        props.setUserTypeFromSignupForm(userType)        

        // email
        const emailRequired = validators.required(
          intl.formatMessage({
            id: 'SignupForm.emailRequired',
          })
        );
        const emailValid = validators.emailFormatValid(
          intl.formatMessage({
            id: 'SignupForm.emailInvalid',
          })
        );

        // password
        const passwordRequiredMessage = intl.formatMessage({
          id: 'SignupForm.passwordRequired',
        });
        const passwordMinLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooShort',
          },
          {
            minLength: validators.PASSWORD_MIN_LENGTH,
          }
        );
        const passwordMaxLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooLong',
          },
          {
            maxLength: validators.PASSWORD_MAX_LENGTH,
          }
        );
        const passwordNotSecureMessage =
          'The password must contain 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character.';
        const passwordMinLength = validators.minLength(
          passwordMinLengthMessage,
          validators.PASSWORD_MIN_LENGTH
        );
        const passwordMaxLength = validators.maxLength(
          passwordMaxLengthMessage,
          validators.PASSWORD_MAX_LENGTH
        );
        const passwordRequired = validators.requiredStringNoTrim(
          passwordRequiredMessage
        );
        const passwordSecurityRequirements = validators.securityRequirements(
          passwordNotSecureMessage
        );
        const passwordValidators = validators.composeValidators(
          passwordRequired,
          passwordMinLength,
          passwordMaxLength,
          passwordSecurityRequirements
        );

        // Custom user fields. Since user types are not supported here,
        // only fields with no user type id limitation are selected.
        const userFieldProps = getPropsForCustomUserFieldInputs(
          userFields,
          intl,
          userType
        );

        const noUserTypes = !userType && !(userTypes?.length > 0);
        const userTypeConfig = userTypes.find(
          config => config.userType === userType
        );
        const showDefaultUserFields = userType || noUserTypes;

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled = invalid || submitInProgress;

        const mapProps = {
          center: new LatLng(41.8781, -87.6298),
          obfuscatedCenter: new LatLng(41.8781, -87.6298),
          address: 'Chicago, IL',
          zoom: 12,
          useMultiMap: false,
          useStaticMap: false,
          onLocationSelect: handleLocationSelect,
          isSellerSelect: true,
        };

        const neighborhoodOptions = userFields?.find((field) => field.key === 'storeNeighborhood')

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <FieldSelectUserType
              name="userType"
              userTypes={userTypes}
              hasExistingUserType={!!preselectedUserType}
              intl={intl}
            />

            {showDefaultUserFields ? (
              <div className={css.defaultUserFields}>
                <FieldTextInput
                  type="email"
                  id={formId ? `${formId}.email` : 'email'}
                  name="email"
                  autoComplete="email"
                  label={
                    isStoreOwner(userType)
                      ? 'Store Email Address*'
                      : 'Email*'

                  }
                  placeholder={
                    isStoreOwner(userType)
                      ? 'Enter the store email address'
                      : intl.formatMessage({
                        id: 'SignupForm.emailPlaceholder',
                      })
                  }
                  validate={validators.composeValidators(
                    emailRequired,
                    emailValid
                  )}
                />
                <FieldTextInput
                  className={css.password}
                  type="password"
                  id={formId ? `${formId}.password` : 'password'}
                  name="password"
                  autoComplete="new-password"
                  label={'Password*'}
                  placeholder={intl.formatMessage({
                    id: isStoreOwner(userType) ? 'SignupForm.passwordPlaceholderSeller' : 'SignupForm.passwordPlaceholder',
                  })}
                  validate={passwordValidators}
                />
                <div className={css.name}>
                  <FieldTextInput
                    className={css.firstNameRoot}
                    type="text"
                    id={formId ? `${formId}.fname` : 'fname'}
                    name="fname"
                    autoComplete="given-name"
                    label={'First name*'}
                    placeholder={
                      isStoreOwner(userType)
                        ? "Enter the owner’s first name"
                        : intl.formatMessage({
                          id: 'SignupForm.firstNamePlaceholder',
                        })
                    }
                    validate={validators.required(
                      intl.formatMessage({
                        id: 'SignupForm.firstNameRequired',
                      })
                    )}
                  />
                  <FieldTextInput
                    className={css.lastNameRoot}
                    type="text"
                    id={formId ? `${formId}.lname` : 'lname'}
                    name="lname"
                    autoComplete="family-name"
                    label={'Last name*'}
                    placeholder={
                      isStoreOwner(userType)
                        ? "Enter the owner’s last name"
                        : intl.formatMessage({
                          id: 'SignupForm.lastNamePlaceholder',
                        })
                    }
                    validate={validators.required(
                      intl.formatMessage({
                        id: 'SignupForm.lastNameRequired',
                      })
                    )}
                  />
                </div>

                <UserFieldDisplayName
                  formName="SignupForm"
                  className={css.row}
                  userTypeConfig={userTypeConfig}
                  intl={intl}
                />

                <UserFieldPhoneNumber
                  formName="SignupForm"
                  className={`${css.row}  ${css.customFieldPhoneNumber} `}
                  userTypeConfig={userTypeConfig}
                  intl={intl}
                  fieldKey={'store_phone_number'}
                />

                <div>
                  <UserField formId={formId} value={values['pub_store_phone_number']} validUrlFormat intl={intl} userFieldProps={userFieldProps} fieldKey={'store_phone_number'} componentType={'phoneNumberField'} />
                </div>

                <UserField formId={formId} userFieldProps={userFieldProps} fieldKey={'store_website'} componentType={'simpleField'} />

                {isStoreOwner(userType) ? <FieldSelectStoreNeighborhood
                  name="storeNeighborhood"
                  storeNeighborhood={neighborhoodOptions.enumOptions}
                  intl={intl}
                /> : null}

                {isStoreOwner(userType) ? (
                  <div className={css.storeLocation}>
                    <label>Enter or Select Store Address*</label>
                    <div className={css.mapSelectBox}>
                      <div className={css.map}>
                        <Map {...mapProps} />
                      </div>
                      {localLocation ? (
                        <div className= {css.addressContainer}>
                          <p className={css.address}>Address: {localLocation.address}</p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}

                <UserField value={values['hoursOperation']} form={form} formId={formId} userFieldProps={userFieldProps} fieldKey={'store_hours'} componentType={'durationSelector'} />

                <UserField formId={formId} userFieldProps={userFieldProps} fieldKey={'store_story'} componentType={'simpleField'} />

                <UserField value={values['pub_store_owner_photo']} formId={formId} userFieldProps={userFieldProps} fieldKey={'store_owner_photo'} componentType={'imageUploadField'} dimensionsInfo='Image Dimensions: 500 x 500 pixels'/>
                <UserField value={values['pub_store_logo']} formId={formId} userFieldProps={userFieldProps} fieldKey={'store_logo'} componentType={'imageUploadField'} dimensionsInfo='Image Dimensions: 500 x 500 pixels'/>
                <UserField value={values['pub_store_photo']} formId={formId} userFieldProps={userFieldProps} fieldKey={'store_photo'} componentType={'imageUploadField'} dimensionsInfo='Image Dimensions: 1000 x 600 pixels'/>

                {/* Social media handlers */}
                <UserField formId={formId} userFieldProps={userFieldProps} fieldKey={'instagram_url'} componentType={'validUrlFormat'} validUrlFormat intl={intl} />
                <UserField formId={formId} userFieldProps={userFieldProps} fieldKey={'tiktok_url'} componentType={'validUrlFormat'} validUrlFormat intl={intl} />
                <UserField formId={formId} userFieldProps={userFieldProps} fieldKey={'facebook_url'} componentType={'validUrlFormat'} validUrlFormat intl={intl} />

              </div>
            ) : null}

            <div className={css.bottomWrapper}>
              {userType ? termsAndConditions : null}
              <div className={userType ? css.signUpButton : css.signUpButton2}>
                <PrimaryButton
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                >
                  <FormattedMessage id="SignupForm.signUp" />
                </PrimaryButton>
              </div>
            </div>
            {
              userType
                ? <div className={css.indiacteRequired}>
                  * Indicates required field
                </div>
                : null
            }
          </Form>
        );
      }}
    />
  );
};

SignupFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
  inProgress: false,
  preselectedUserType: null,
};

SignupFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  inProgress: bool,
  termsAndConditions: node.isRequired,
  preselectedUserType: string,
  userTypes: propTypes.userTypes.isRequired,
  userFields: propTypes.listingFields.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
